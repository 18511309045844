(function ($, window, undefined) {

    if ($('#scoreboard').length > 0) {

        $('.hide-name').each(function (index, element) {
            const originalText = $(this).text();
            let modifiedText = '';
            modifiedText = originalText.charAt(0) + originalText.slice(1).replace(/[a-zA-Z]/g, '*');
            $(this).text(modifiedText);
        });
    }

})(jQuery, window);