(function ($, window, undefined) {

    const scoreBoard = $('#scoreboard');
    const solutionPanel = $('#solutions-panel');
    const xRayOne = $('#x-ray-one-overlays');
    const xRayTwo = $('#x-ray-two-overlays');
    const xRayThree = $('#x-ray-three-overlays');
    const demoPanel = $('.get-demo-panel');

    $(document).ready(function() {
        setTimeout(function() {
            demoPanel.addClass('show');
        }, 15000); // 15000 milliseconds = 15 seconds
    });

    $(document).on("click", ".view-solutions" , function() {
        solutionPanel.addClass('slide');
        // $('body').addClass('overflow');
    });

    $(document).on("click", ".close-solutions" , function() {
        solutionPanel.removeClass('slide');
        setTimeout(function() {
            demoPanel.addClass('show');
        }, 3000);
    });

    if ($('#my-score').length > 0) {
        scoreBoard.addClass('fixed');
        const myScoreElement = document.getElementById('my-score');
        myScoreElement.scrollIntoView({ block: 'center' });
        setTimeout(function() {
            scoreBoard.removeClass('fixed');
        }, 800);
    }

    $(document).on("click", ".x-ray-one-findings" , function() {
        $('.x-ray-one-findings').removeClass('current');
        xRayOne.removeClass('your-overlay-one ai-overlay-one');
        $(this).addClass('current');
        if($(this).hasClass('ai')){
            xRayOne.addClass('ai-overlay-one');
        } else{
            xRayOne.addClass('your-overlay-one');
        }
    });

    $(document).on("click", ".x-ray-two-findings" , function() {
        $('.x-ray-two-findings').removeClass('current');
        xRayTwo.removeClass('your-overlay-two ai-overlay-two');
        $(this).addClass('current');
        if($(this).hasClass('ai')){
            xRayTwo.addClass('ai-overlay-two');
        } else{
            xRayTwo.addClass('your-overlay-two');
        }
    });

    $(document).on("click", ".x-ray-three-findings" , function() {
        $('.x-ray-three-findings').removeClass('current');
        xRayThree.removeClass('your-overlay-three ai-overlay-three');
        $(this).addClass('current');
        if($(this).hasClass('ai')){
            xRayThree.addClass('ai-overlay-three');
        } else{
            xRayThree.addClass('your-overlay-three');
        }
    });

})(jQuery, window);