(function ($, window, undefined) {
    if ($("#landing-screen").length > 0 && !$("radio-container").hasClass('no-guess')) {
        const canvas = document.getElementById("game-canvas");
        const container = $("#radio-container");
        const containerBis = document.getElementById("radio-container");
        const pathos = $("#pathologies-list");
        let counterCarry = 0;
        let counterCalculus = 0;
        let counterMargin = 0;
        let counterRadio = 0;
        const circleTemplate = $(".circle-template");
        const circleTemplateWidth = circleTemplate.width();
        const circleTemplateHeight = circleTemplate.height();

        containerBis.addEventListener("click", function (event) {
            if (container.hasClass("add-new")) {
                if (!pathos.hasClass("has-item")) {
                    pathos.addClass("has-item");
                    $("#first-radio, #second-radio, #third-radio").removeClass(
                        "inactive"
                    );
                }
                const mouseX = event.clientX - canvas.getBoundingClientRect().left;
                const mouseY = event.clientY - canvas.getBoundingClientRect().top;
                const div = document.createElement("div");
                div.style.top = mouseY - circleTemplateWidth / 2 + "px";
                div.style.left = mouseX - circleTemplateHeight / 2 + "px";
                containerBis.append(div);
                if (container.hasClass("carry")) {
                    div.id = "car-" + counterCarry;
                    div.className = "overlayDiv is-carie draggable";
                    $("#car-" + counterCarry).append([
                        '<button class="remove-circle"></button>',
                        '<span class="info-text carie">Caries</span>',
                        '<span class="circle-center"></span>',
                    ]);
                    const itemCarry = $(
                        "<li class='list-item carry'>" +
                        "<span>Caries</span><button class='remove'></button></li>"
                    ).attr("data-number", "car-" + counterCarry);
                    pathos.append(itemCarry);
                    if (
                        event.target.classList.contains("is-first-carie") ||
                        event.target.classList.contains("is-second-carie") ||
                        event.target.classList.contains("is-third-carie") ||
                        event.target.classList.contains("is-fourth-carie") ||
                        event.target.classList.contains("is-fifth-carie") ||
                        event.target.classList.contains("is-sixth-carie") ||
                        event.target.classList.contains("is-seventh-carie") ||
                        event.target.classList.contains("is-eighth-carie") ||
                        event.target.classList.contains("is-ninth-carie") ||
                        event.target.classList.contains("is-tenth-carie") ||
                        event.target.classList.contains("is-eleventh-carie") ||
                        event.target.classList.contains("is-twelfth-carie") ||
                        event.target.classList.contains("is-thirteenth-carie") ||
                        event.target.classList.contains("is-fourteenth-carie") ||
                        event.target.classList.contains("is-fifteenth-carie")
                    ) {
                        $("#car-" + counterCarry).attr("data-guessing", "PP");
                    } else {
                        $("#car-" + counterCarry).attr("data-guessing", "PN");
                    }
                    counterCarry++;
                } else if (container.hasClass("calculus")) {
                    div.id = "cal-" + counterCalculus;
                    div.className = "overlayDiv calculus draggable";
                    $("#cal-" + counterCalculus).append([
                        '<button class="remove-circle"></button>',
                        '<span class="info-text calculus">Calculus</span>',
                        '<span class="circle-center"></span>',
                    ]);
                    const itemCalculus = $(
                        "<li class='list-item calculus'><span>Calculus</span><button class='remove'></button></li>"
                    ).attr("data-number", "cal-" + counterCalculus);
                    pathos.append(itemCalculus);
                    if (
                        event.target.classList.contains("is-first-calculus") ||
                        event.target.classList.contains("is-second-calculus") ||
                        event.target.classList.contains("is-third-calculus") ||
                        event.target.classList.contains("is-fourth-calculus") ||
                        event.target.classList.contains("is-fifth-calculus") ||
                        event.target.classList.contains("is-sixth-calculus") ||
                        event.target.classList.contains("is-seventh-calculus") ||
                        event.target.classList.contains("is-eighth-calculus") ||
                        event.target.classList.contains("is-ninth-calculus") ||
                        event.target.classList.contains("is-tenth-calculus") ||
                        event.target.classList.contains("is-eleventh-calculus") ||
                        event.target.classList.contains("is-twelfth-calculus")
                    ) {
                        $("#cal-" + counterCalculus).attr("data-guessing", "PP");
                    } else {
                        $("#cal-" + counterCalculus).attr("data-guessing", "PN");
                    }
                    counterCalculus++;
                } else if (container.hasClass("margin")) {
                    div.id = "mar-" + counterMargin;
                    div.className = "overlayDiv margin draggable";
                    $("#mar-" + counterMargin).append([
                        '<button class="remove-circle"></button>',
                        '<span class="info-text margin">Notable Margin</span>',
                        '<span class="circle-center"></span>',
                    ]);
                    const itemMargin = $(
                        "<li class='list-item margin'><span>Notable Margin</span><button class='remove'></button></li>"
                    ).attr("data-number", "mar-" + counterMargin);
                    pathos.append(itemMargin);
                    if (
                        event.target.classList.contains("is-first-margin") ||
                        event.target.classList.contains("is-second-margin") ||
                        event.target.classList.contains("is-third-margin") ||
                        event.target.classList.contains("is-fourth-margin")
                    ) {
                        $("#mar-" + counterMargin).attr("data-guessing", "PP");
                    } else {
                        $("#mar-" + counterMargin).attr("data-guessing", "PN");
                    }
                    counterMargin++;
                } else {
                    div.id = "rad-" + counterRadio;
                    div.className = "overlayDiv radio draggable";
                    $("#rad-" + counterRadio).append([
                        '<button class="remove-circle"></button>',
                        '<span class="info-text radio">Periapical radiolucency</span>',
                        '<span class="circle-center"></span>',
                    ]);
                    const itemRadio = $(
                        "<li class='list-item radio'><span>Periapical radiolucency</span><button class='remove'></button></li>"
                    ).attr("data-number", "rad-" + counterRadio);
                    pathos.append(itemRadio);
                    if (
                        event.target.classList.contains("is-first-radio") ||
                        event.target.classList.contains("is-second-radio") ||
                        event.target.classList.contains("is-third-radio") ||
                        event.target.classList.contains("is-fourth-radio")
                    ) {
                        $("#rad-" + counterRadio).attr("data-guessing", "PP");
                    } else {
                        $("#rad-" + counterRadio).attr("data-guessing", "PN");
                    }
                    counterRadio++;
                }
                // $(".button-overlay").removeClass("s-carie s-calculus s-margin s-radio");
                // container.removeClass("carry calculus margin radio add-new");
            }
        });
    }
})(jQuery, window);
