import 'jquery-circle-progress';

(function ($, window, undefined) {

    let firstValue = $('#circle-a').data('sensitivity');
    let firstLightValue = firstValue / 100;
    const circleB = $('#circle-b');
    const circleAI = $('#ai-circle');
    const circleAIbis = $('#ai-circle-bis');
    let secondValue = circleB.data('precision');
    let secondLightValue = secondValue / 100;
    let progressBarOptions = {
        startAngle: -1.55,
        size: 150,
        lineCap: 'round',
        value: firstLightValue,
        fill: {
            color: '#73cca6'
        }
    }

    $('.circle').circleProgress(progressBarOptions).on('circle-animation-progress', function(event, progress, stepValue) {
        // $(this).find('strong').text(firstValue);
    });

    circleB.circleProgress({
        value : secondLightValue,
        fill: {
            color: '#cc6600'
        }
    });

    circleAI.circleProgress({
        value : 0.96,
        fill: {
            color: '#40A9FF'
        }
    });

    circleAIbis.circleProgress({
        value : 0.96,
        fill: {
            color: '#40A9FF'
        }
    });


})(jQuery, window);