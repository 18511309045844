(function ($, window, undefined) {

    'use strict';

    const demoPanel = $('.get-demo-panel');

    $(".ask-for-demo").click(function(e){
        e.stopPropagation();
        demoPanel.addClass('show');
    });

    $(".close-demo").click(function(){
        demoPanel.removeClass('show');
    });

})(jQuery, window);