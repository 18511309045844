import '../scss/app.scss';

const $ = require('jquery');
global.$ = global.jQuery = $;

import './app/slick';
import './app/firstScreen';
import './app/cursor';
import './app/addCricle';
import './app/dragCircle';
import './app/removeCircle';
import './app/solutions';
import './app/screenshot';
import './app/percentages';
import './app/hideNames';
import './app/score';
import './app/hover';
import './app/sliders';
import './app/submit';
import './app/getDemo';