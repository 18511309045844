(function ($, window, undefined) {

    'use strict';

    $(document).on("mouseenter mouseleave", ".list-item", function (e) {
        let itemData = $(this).attr("data-number");
        if (e.type === "mouseenter") {
            $("#" + itemData).addClass('show-infos');
        } else {
            $("#" + itemData).removeClass('show-infos');
        }
    });

    $(document).on("mouseenter mouseleave", ".hover-solution", function (e) {
        let pathoData = $(this).attr("data-pathos");
        if (e.type === "mouseenter") {
            $("." + pathoData).addClass('see-patho');
        } else {
            $("." + pathoData).removeClass('see-patho');
        }
    });

})(jQuery, window);