(function($, window, undefined) {

    $('.solutions-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        draggable: false,
        autoplay: false,
        swipeToSlide: false,
        fade: true,
        cssEase: 'linear',
        speed: 600,
        dots: false,
        infinite: false,
        prevArrow: $('.prev'),
        nextArrow: $('.next'),
    });

})(jQuery, window);