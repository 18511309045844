import html2canvas from "html2canvas";

(function ($, window, undefined) {
    $(document).ready(function () {
        const radioOneCta = $("#first-radio");
        const radioTwoCta = $("#second-radio");
        const radioThreeCta = $("#third-radio");
        const radioStepTwo = $(".second-radio");
        const radioStepThree = $(".third-radio");
        const loader = $(".datas-loader");
        const pathoList = $("#pathologies-list");
        const marker = $(".marker");
        const eventSlug = $("#game-screen").data("event-slug") ?? null
        const eventId = $("#game-screen").data("id") ?? null
        let rayNbr = $(".ray-nbr");
        let firstnameInput;
        let lastnameInput;
        let emailInput;
        let submitButton;
        let playerId = null;
        let file;
        let ranking = $("#ranking");

        // let testSizes = document.getElementById("radio-container");

        window.addEventListener('load', function () {

            // let divW = testSizes.offsetWidth;
            // let divH = testSizes.offsetHeight;

            const svgElements = document.querySelectorAll('.dropzone');
            const elementsArray = Array.from(svgElements);
            // elementsArray.forEach(drop => {
            //     drop.setAttribute("width", divW + 'px');
            //     drop.setAttribute("height", divH + 'px');
            //     drop.style.width = null;
            //     drop.style.height = null;
            // });
        })

        radioOneCta.on("click", async function () {
            loader.addClass("loading");
            await sendData(playerId, [4, 0, 0, 3]);
            setTimeout(function() {
                loader.removeClass("loading");
                $(".overlayDiv").remove();
                $(".list-item").remove();
                $(".first-radio").remove();
                radioStepTwo.addClass("active");
                marker.addClass("second");
                rayNbr.text("2");
                radioOneCta.addClass("step-done");
                radioTwoCta.addClass("step-to-do inactive");
                pathoList.removeClass("has-item");
            }, 8000);
        });

        radioTwoCta.on("click", async function () {
            loader.addClass("loading");
            await sendData(playerId, [5, 12, 0, 0]);
            setTimeout(function() {
                loader.removeClass("loading");
                $(".overlayDiv").remove();
                $(".list-item").remove();
                radioStepTwo.remove();
                radioStepThree.addClass("active");
                marker.addClass("end");
                rayNbr.text("3");
                radioTwoCta.addClass("step-done");
                radioThreeCta.addClass("step-to-submit inactive");
                pathoList.removeClass("has-item");
            }, 8000);
        });

        radioThreeCta.on("click", async function () {
            loader.addClass("loading");
            await sendData(playerId, [15, 0, 1, 0]);
            setTimeout(function() {
                loader.removeClass("loading");
                $(".list-item").remove();
                $(".game-content").addClass("no-cursor");
                $(".create").addClass("hide");
                $(".cta-container").addClass("remove");
                $(".result").addClass("show");
                $(".hs-submit").addClass("locked");
                pathoList.removeClass("has-item");
                $(".to-change").html("Calculating your score...");
            }, 8000);
            firstnameInput = $('input[name="firstname"]');
            lastnameInput = $('input[name="lastname"]');
            emailInput = $('input[name="email"]');
            submitButton = $('input[type="submit"]');
            submitButton.on("click", async function () {
                loader.addClass("loading");
                await getPlayerInfos();
            });
        });

        async function getPlayerInfos() {
            let emailRegex =
                /^[a-zA-Z0-9!#$%&'*+-\/=?^_`{|}~\\]+@[a-zA-Z0-9-]+[a-zA-Z0-9]+\.[a-zA-Z-]+$/;

            if (emailInput[0].value.match(emailRegex)) {
                let data = {
                    firstname: firstnameInput.val(),
                    lastname: lastnameInput.val(),
                    email: emailInput.val(),
                };
                await fetch("/api/v1/player-registration/" + playerId, {
                    method: "PUT",
                    body: JSON.stringify(data),
                })
                    .then((r) => r.json())
                    .then(function (r) {
                        loadScoreboard();
                    });
            } else {
                loader.removeClass("loading");
            }
        }

        function loadScoreboard() {
            if(eventSlug != null){
                window.location = "/scoreboard/" + playerId + "?event=" + eventSlug;
            }else{
                window.location = "/scoreboard/" + playerId;
            }
        }

        async function sendData(id = null, toFind) {
            loader.addClass("loading");
            let form = new FormData();
            if (id !== null) {
                form.append("playerId", playerId);
            }

            let vp = document.getElementById("viewportMeta").getAttribute("content");
            // const canvas = document.getElementById("game-canvas");
            // // canvas.getContext('2d', {
            // //     willReadFrequently: true,
            // //     windowWidth: canvas.scrollWidth,
            // //     windowHeight: canvas.scrollHeight
            // // });
            let div = document.getElementById("radio-container");

            await html2canvas(div, {
                // canvas: document.getElementById("game-canvas"),
                willReadFrequently: true,
                backgroundColor: null,
                allowTaint: true,
                imageTimeout: 0,
                useCORS: true,
                width: div.scrollWidth,
                height: div.scrollHeight
            }).then(async function (screenshotImg) {
                document.getElementById("viewportMeta").setAttribute("content", "width=800");
                let img = new Image();
                img.src = screenshotImg.toDataURL("image/jpg",0.1);
                file = DataURIToBlob(img.src);
                form.append("playing", file, "image.jpg");
                let results = getResults(toFind);
                form.append("ppCaries", results[0][0]);
                form.append("pnCaries", results[0][1]);
                form.append("nnCaries", results[0][2]);
                form.append("ppCalculus", results[1][0]);
                form.append("pnCalculus", results[1][1]);
                form.append("nnCalculus", results[1][2]);
                form.append("ppMargins", results[2][0]);
                form.append("pnMargins", results[2][1]);
                form.append("nnMargins", results[2][2]);
                form.append("ppRadios", results[3][0]);
                form.append("pnRadios", results[3][1]);
                form.append("nnRadios", results[3][2]);
                if(eventId != null){
                    form.append("eventId", eventId);
                }
                await fetch("/api/v1/playing", {
                    method: "POST",
                    body: form,
                })
                    .then((r) => r.json())
                    .then(function (r) {
                        playerId = r[0];
                        ranking.text(r[1]);
                    })
                    .then(function () {
                        document.getElementById("viewportMeta").setAttribute("content", vp);
                    });
            }).catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
        }

        function DataURIToBlob(dataURI) {
            const splitDataURI = dataURI.split(",");
            const byteString =
                splitDataURI[0].indexOf("base64") >= 0
                    ? atob(splitDataURI[1])
                    : decodeURI(splitDataURI[1]);
            const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

            const ia = new Uint8Array(byteString.length);
            for (let i = 0; i < byteString.length; i++)
                ia[i] = byteString.charCodeAt(i);

            return new Blob([ia], {
                type: mimeString,
            });
        }

        /**
         *
         * @param toFind //array of the diseases to find [caries, calculus, margins, radios]
         * @returns {(number|jQuery)[][]}
         */
        function getResults(toFind) {
            let guessCaries = $(".overlayDiv.is-carie");
            let guessCalculus = $(".overlayDiv.calculus");
            let guessMargins = $(".overlayDiv.margin");
            let guessRadios = $(".overlayDiv.radio");

            let caries = getDiseaseResults(guessCaries, 'is-carie', toFind[0]);
            let calculus = getDiseaseResults(guessCalculus, 'calculus', toFind[1]);
            let margins = getDiseaseResults(guessMargins, 'margin', toFind[2]);
            let radios = getDiseaseResults(guessRadios, 'radio', toFind[3]);

            return [
                [caries[0], caries[1], caries[2]],
                [calculus[0], calculus[1], calculus[2]],
                [margins[0], margins[1], margins[2]],
                [radios[0], radios[1], radios[2]],
            ];
        }

        function getDiseaseResults(guessDisease, diseaseName, toFindDisease) {
            if (guessDisease.length > 0) {
                let countPP = $('.overlayDiv.' + diseaseName + '[data-guessing="PP"]').length;
                let countPN = $('.overlayDiv.' + diseaseName + '[data-guessing="PN"]').length;
                return [countPP, countPN, toFindDisease - countPP];
            } else {
                return [0, 0, toFindDisease];
            }
        }


    });
})(jQuery, window);
