(function ($, window, undefined) {

    'use strict';

    $(document).on('input', '.hbspt-form', function () {

        if ($('.hs-input[name=firstname]').val() !== ""
            && $('.hs-input[name=lastname]').val() !== ""
            && $('.hs-input[name=email]').val() !== "") {
            $('.hs-submit').removeClass('locked');
        } else{
            $('.hs-submit').addClass('locked');
        }

    });

})(jQuery, window);