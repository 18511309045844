(function ($, window, undefined) {

   const scoreGrid = $('.score-grid');

        if (scoreGrid.length > 0){

            let distance = scoreGrid.offset().top;

            const $window = $(window);
            const elementToFixed = $('.score-right');

            $window.scroll(function () {
                if ($window.scrollTop() >= distance) {
                    elementToFixed.addClass('fixed');
                } else {
                    elementToFixed.removeClass('fixed');
                }
            });


            function animateNumber(targetNumber) {
                let currentNumber = 0;
                let interval = setInterval(function () {
                    document.getElementById('animatedNumber').textContent = currentNumber;
                    currentNumber++;
                    if (currentNumber > targetNumber) {
                        clearInterval(interval);
                    }
                }, 10);
            }

            $(document).ready(function () {
                setTimeout(function () {
                    animateNumber(96);
                }, 1000);
            });
        }

})(jQuery, window);