import interact from "interactjs";

(function ($, window, undefined) {
  const radioContainer = $('#radio-container');
  let classArrayDrag = [];
  let target = null;
  // target elements with the "draggable" class
  interact(".draggable").draggable({
    allowFrom: ".circle-center",
    // enable inertial throwing
    inertia: true,
    // keep the element within the area of it's parent
    modifiers: [
      interact.modifiers.restrictRect({
        restriction: "self",
        endOnly: true,
        elementRect: { top: 0, left: 0, bottom: 1, right: 1 },
      }),
    ],
    // enable autoScroll
    autoScroll: true,
    listeners: {
      // call this function on every dragmove event
      move: dragMoveListener,
      // call this function on every dragend event
      end(event) {},
    },
  });
  function dragMoveListener(event) {
    target = event.target;

    // keep the dragged position in the data-x/data-y attributes
    let x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
    let y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;
    // translate the element
    target.style.transform = "translate(" + x + "px, " + y + "px)";
    // update the posiion attributes
    target.setAttribute("data-x", x);
    target.setAttribute("data-y", y);
  }
  // this function is used later in the resizing and gesture demos
  window.dragMoveListener = dragMoveListener;

  // enable draggables to be dropped into this
  interact(".dropzone rect").dropzone({
    ondropactivate: function (event) {},

    ondragenter: function (event) {
      let classesDrag = radioContainer.attr('class').split(" ");
      classArrayDrag.push(classesDrag);
      radioContainer.removeClass("carry calculus margin radio add-new");
    },

    ondragleave: function (event) {},

    ondrop: function (event) {
      if (target.classList.contains("is-carie")) {
        if (
          event.target.classList.contains("is-first-carie") ||
          event.target.classList.contains("is-second-carie") ||
          event.target.classList.contains("is-third-carie") ||
          event.target.classList.contains("is-fourth-carie") ||
          event.target.classList.contains("is-fifth-carie") ||
          event.target.classList.contains("is-sixth-carie") ||
          event.target.classList.contains("is-seventh-carie") ||
          event.target.classList.contains("is-eighth-carie") ||
          event.target.classList.contains("is-ninth-carie") ||
          event.target.classList.contains("is-tenth-carie") ||
          event.target.classList.contains("is-eleventh-carie") ||
          event.target.classList.contains("is-twelfth-carie") ||
          event.target.classList.contains("is-thirteenth-carie") ||
          event.target.classList.contains("is-fourteenth-carie") ||
          event.target.classList.contains("is-fifteenth-carie")
        ) {
          target.setAttribute("data-guessing", "PP");
        } else {
          target.setAttribute("data-guessing", "PN");
        }
      } else if (target.classList.contains("calculus")) {
        if (
          event.target.classList.contains("is-first-calculus") ||
          event.target.classList.contains("is-second-calculus") ||
          event.target.classList.contains("is-third-calculus") ||
          event.target.classList.contains("is-fourth-calculus") ||
          event.target.classList.contains("is-fifth-calculus") ||
          event.target.classList.contains("is-sixth-calculus") ||
          event.target.classList.contains("is-seventh-calculus") ||
          event.target.classList.contains("is-eighth-calculus") ||
          event.target.classList.contains("is-ninth-calculus") ||
          event.target.classList.contains("is-tenth-calculus") ||
          event.target.classList.contains("is-eleventh-calculus") ||
          event.target.classList.contains("is-twelfth-calculus")
        ) {
          target.setAttribute("data-guessing", "PP");
        } else {
          target.setAttribute("data-guessing", "PN");
        }
      } else if (target.classList.contains("margin")) {
        if (
          event.target.classList.contains("is-first-margin") ||
          event.target.classList.contains("is-second-margin") ||
          event.target.classList.contains("is-third-margin") ||
          event.target.classList.contains("is-fourth-margin")
        ) {
          target.setAttribute("data-guessing", "PP");
        } else {
          target.setAttribute("data-guessing", "PN");
        }
      } else {
        if (
          event.target.classList.contains("is-first-radio") ||
          event.target.classList.contains("is-second-radio")
        ) {
          target.setAttribute("data-guessing", "PP");
        } else {
          target.setAttribute("data-guessing", "PN");
        }
      }

      setTimeout(function() {
        event.preventDefault();
        classArrayDrag.forEach(classesDrag => {
          radioContainer.addClass(classesDrag);
        });
        classArrayDrag = [];
      }, 750);
    },

    ondropdeactivate: function (event) {},

  });
})(jQuery, window);
