(function ($, window, undefined) {

    const radioContainer = $('#radio-container');
    let classArray = [];

    $(document).on('mouseenter', '.remove-circle', function(e) {
        removeClasses();
    });

    $(document).on('mouseleave', '.remove-circle', function(e) {
        addClasses();
    });

    $(document).on("click", ".remove" , function() {
        let data = $(this).parent().attr("data-number");
        $(this).parent().remove();
        $("#" + data).remove();
        if($('.remove').length === 0){
            clearBoard();
        }
    });

    $(document).on("click", ".remove-circle" , function() {
        let id = $(this).parent().attr("id");
        $(this).parent().remove();
        $('.list-item[data-number="' + id + '"]').remove();
        if($('.remove-circle').length === 0){
            clearBoard();
        }
        addClasses();
    });

    function removeClasses(){
        let classes = radioContainer.attr('class').split(" ");
        classArray.push(classes);
        radioContainer.removeClass("carry calculus margin radio add-new");
    }

    function addClasses(){
        classArray.forEach(classes => {
            radioContainer.addClass(classes);
        });
        classArray = [];
    }

    function clearBoard(){
        $('#pathologies-list').removeClass('has-item');
        $('#first-radio, #second-radio, #third-radio').addClass('inactive');
    }

})(jQuery, window);