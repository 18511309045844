(function ($, window, undefined) {

    'use strict';

    $("#open-cta").click(function() {
        $('#landing-screen').addClass('go-next');
        $('#game-screen').addClass('show');
    });

    if ($('#login-screen').length > 0) {

        const passwordInput = document.getElementById('password');
        const toggleButton = document.getElementById('pwd-reveal');

        toggleButton.addEventListener('mouseover', function() {
            passwordInput.type = 'text';
        });

        toggleButton.addEventListener('mouseout', function() {
            passwordInput.type = 'password';
        });
    }

})(jQuery, window);