(function ($, window, undefined) {

    'use strict';

    const radioContainer = $('#radio-container');

    $(".add-pathology").click(function() {
        radioContainer.removeClass("carry calculus margin radio");
        $('.button-overlay').removeClass("s-carie s-calculus s-margin s-radio");
        if($(this).hasClass('carry')){
            $(this).parent().addClass('s-carie');
            radioContainer.addClass('carry add-new');
        } else if($(this).hasClass('calculus')){
            $(this).parent().addClass('s-calculus');
            radioContainer.addClass('calculus add-new');
        } else if($(this).hasClass('margin')){
            $(this).parent().addClass('s-margin');
            radioContainer.addClass('margin add-new');
        } else {
            $(this).parent().addClass('s-radio');
            radioContainer.addClass('radio add-new');
        }
    });

})(jQuery, window);